<template>
    <div>
        <div class="top">
            <div class="title">优惠券</div>
        </div>
        <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="chaneTabs">
                <el-tab-pane label="未使用" name="1">
                    <div class="card-main">
                        <div
                            class="card"
                            v-for="(item, index) in list"
                            :key="index"
                        >
                            <div class="card-top">
                                <div style="margin-top:8px">
                                    <span v-if="item.Type == 1"
                                        >小类优惠券</span
                                    >
                                    <span v-if="item.Type == 2">全场通用</span>
                                </div>
                                <div>
                                    ￥<span class="money">{{
                                        item.Money
                                    }}</span>
                                </div>
                            </div>
                            <div class="info">
                                <div class="info-text">
                                    <span v-if="item.MinPrice > 0">
                                        满{{ item.MinPrice }}减{{ item.Money }}
                                    </span>
                                    <span v-else> 通用{{ item.Money }} </span>
                                </div>
                                <div class="date">
                                    有效期至 - {{ item.OverTime }}
                                </div>
                                <div class="button" @click="handleToMake(item)">
                                    去凑单
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="已过期" name="2">
                    <div class="card-main">
                        <div
                            class="card"
                            v-for="(item, index) in list"
                            :key="index"
                        >
                            <div class="card-top">
                                <div style="margin-top:8px">
                                    <span v-if="item.Type == 1"
                                        >小类优惠券</span
                                    >
                                    <span v-if="item.Type == 2">全场通用</span>
                                </div>
                                <div>
                                    ￥<span class="money">{{
                                        item.Money
                                    }}</span>
                                </div>
                            </div>
                            <img
                                v-if="item.State == 2"
                                class="yishiyong"
                                src="../../assets/yishiyong.png"
                                alt=""
                            />
                            <div class="info">
                                <div class="info-text">
                                    <span v-if="item.MinPrice > 0">
                                        满{{ item.MinPrice }}减{{ item.Money }}
                                    </span>
                                    <span v-else> 通用{{ item.Money }} </span>
                                </div>
                                <div class="date">
                                    有效期至 - {{ item.OverTime }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { getList } from './service'

export default {
    data() {
        return {
            pid: 0,
            list: [],
            activeName: '1',
            data: {
                PageIndex: 1,
                PageSize: 100,
                State: 1
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        handleToMake(item) {
            if (item.Type == 1) {
                window.open(
                    `/product-list?type=6&BigType=${item.BigType}&SmaType=${item.SmaType}&MidType=${item.MidType}`,
                    '_blank'
                ).location

                return
            }
            window.open('/product-list', '_blank').location
        },
        chaneTabs() {
            switch (this.activeName) {
                case '1':
                    this.data.State = 1
                    break
                case '2':
                    this.data.State = 2
                    break
            }
            this.init()
        },
        init() {
            getList(this.data).then(res => {
                this.list = res.data
            })
        }
    }
}
</script>

<style lang="less" scoped>
.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }
}

.card-main {
    display: flex;
    flex-wrap: wrap;

    .card {
        margin: 20px;
        position: relative;
    }
}
.yishiyong {
    position: absolute;
    right: 10px;
    top: 65px;
}

.card {
    width: 300px;
    border: 1px solid #eeeeee;

    .card-top {
        display: flex;
        font-family: 'ct';
        font-size: 16px;
        color: #0097ba;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px dotted #dcdcdc;
        .money {
            font-size: 24px;
        }
    }

    .info {
        padding: 10px 20px;
        font-size: 12px;
        .info-text {
            font-size: 14px;
            font-family: 'ct';
        }
        .date {
            color: #999999;
            margin-top: 5px;
        }

        .button {
            width: fit-content;
            padding: 5px 15px;
            margin: 20px auto 0 auto;
            border: 1px solid #0097ba;
            border-radius: 15px;
            color: #0097ba;
            cursor: pointer;
        }
    }
}
</style>
<style scoped>
.tabs {
    margin-top: 20px;
}

>>> .el-tabs__nav-wrap {
    background: #f9f9f9;
}

>>> .el-tabs__nav-wrap::after,
>>> .el-tabs__active-bar {
    height: 0;
}

>>> .is-active {
    color: #dc2310;
}

>>> .el-tabs__item:hover {
    color: #dc2310;
}

>>> .el-tabs__nav-scroll {
    padding: 0 20px;
}
</style>
